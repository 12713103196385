import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "home",
  //   component: () => import("../views/Home.vue"),
  // },
  {
    path: "/",
    name: "privacy",
    component: () => import("../views/Privacy.vue"),
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("../views/Terms.vue"),
  },
  {
    path: "/download",
    name: "download",
    component: () => import("../views/Home.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
