<template>
  <v-app>
    <v-app-bar app fixed color="#FFFFFF" src="@/assets/bgHeader2.png" fade-img-on-scroll
      scroll-target="#scrolling-techniques-3" :elevation="1" height="100">
      <template v-slot:img="{ props }">
        <v-img v-bind="props" gradient="to top right, rgba(255,255,255,.7), rgba(255,255,255,.7)"></v-img>
      </template>

      <v-row class="mx-5" align="center">
        <v-col cols="auto">
          <img src="@/assets/logoApp.png" height="60px" alt="" />
        </v-col>
        <v-cols>
          <h3>الدليل - دليلك للوظائف&nbsp;&nbsp;&nbsp;&nbsp;</h3>
        </v-cols>
      </v-row>

      <template v-slot:extension>
        <v-tabs background-color="info" align-with-title>
          <!-- <v-tab to="/" class="white--text">الرئيسية</v-tab> -->
          <v-tab to="/" class="white--text">الخصوصية</v-tab>
          <v-tab to="/terms" class="white--text">سياسة الاستخدام</v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
    <v-footer dark padless>
      <v-card class="flex" flat tile>
        <v-card-title class="pr-10" style="background-color: #0e5f81">
          <img src="@/assets/logoApp.png" height="30px" class="animate__animated animate__bounceIn" alt="" />
          &nbsp; &nbsp; &nbsp;
          <strong class="subheading hidden-sm-and-down">الدليل - دليلك للوظائف</strong>

          <v-spacer></v-spacer>


        </v-card-title>

        <v-card-text class="py-10 white--text text-center">
          {{ new Date().getFullYear() }} &copy;
          <strong>Al-Daleel platform</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({}),
};
</script>
<style src="./style.css"></style>